import React from 'react'
import {graphql} from 'gatsby'
import Wrapper from '../components/wrapper'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {toPlainText} from '../lib/helpers'

export const query = graphql`
  query PortfolioTemplateQuery($id: String!) {
  portfolio: sanityPortfolio(id: {eq: $id}) {
    id
    publishedAt
    mainImage {
      ...SanityImage
      alt
      asset{
        fluid {
          ...GatsbySanityImageFluid_withWebp_noBase64
        }
      }
    }
    title
    slug {
      current
    }
    _rawExcerpt(resolveReferences: {maxDepth: 5})
    _rawBody(resolveReferences: {maxDepth: 5})
    authors {
      _key
      author {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        name
      }
    }
  }
}
`

const PortfolioTemplate = props => {
  const {data, errors} = props
  const portfolio = data && data.portfolio
  
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {portfolio && <SEO title={portfolio.title || 'No title'} description={toPlainText(portfolio._rawExcerpt)} image={portfolio.mainImage} />}

      {errors && (
        <Wrapper>
          <GraphQLErrorList errors={errors} />
        </Wrapper>
      )}

      {portfolio && <h1>{portfolio.title}</h1>}
    </Layout>
  )
}

export default PortfolioTemplate
